import style from './Home.module.scss';

// Components
import Blogs from '../../components/home/blogs/Blogs';
import Packages from '../../components/home/packages/Packages';


const Home = () => {
    return (
        <div className={style.home_page}>
            <div className={style.home_page__welcome}>
                <p>Здравствуйте, Александр!</p>
            </div>
            <Blogs />
            <Packages title='Консультации, которые подойдут именно Вам' />
        </div>
    )
}

export default Home;