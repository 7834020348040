import style from './ServicePay.module.scss';

import { useEffect, useState } from 'react';

// Ant Design
import { message as antdMessage } from 'antd';
import { Checkbox, CheckboxProps } from 'antd';

// Telegram
import { MainButton, BackButton, useWebApp, WebAppUser } from '@vkruglikov/react-telegram-web-app';

// Router
import { useParams, useNavigate } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { setPackageByAlias } from '../../redux/packages/slice';
import { PromoCodeFormData } from '../../redux/promocode/types';
import { selectPromoCode } from '../../redux/promocode/selectors';
import { requestActivatePromoCode } from '../../redux/promocode/asyncActions';

// Component
import Spinner from '../../components/utils/spinner/Spinner';

// Types
import { Status } from '../../types/types';
import { selectPackages } from '../../redux/packages/selectors';
import { selectPayments } from '../../redux/payments/selectors';
import { CreateUrlPaymentFormData } from '../../redux/payments/types';
import { requestCreatePaymentUrl } from '../../redux/payments/asyncActions';
import { setPromocodeIsNull } from '../../redux/promocode/slice';
import { setPaymentUrlIsNull } from '../../redux/payments/slice';
import { requestMyServices } from '../../redux/my-services/asyncActions';


const ServicePay = () => {
    // Navigate
    const navigate = useNavigate();
    const { activePackage } = useSelector(selectPackages);

    // Get Path
    const { alias, verbose_name, price } = useParams();

    // Redux
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (alias) {
            dispatch(setPackageByAlias({alias: alias}))
        }
    }, [dispatch, alias])

    // Telegram
    const WebApp = useWebApp();

    // Promocode
    const [promocode, setPromocode] = useState<string>('');

    // Show Alert
    const [messageApi, contextHolder] = antdMessage.useMessage();
    const { status, message, promo_package } = useSelector(selectPromoCode);

    // Activate Promocode
    const handlerActivatePromocode = (e: any) => {
        e.preventDefault();
        e.target.disabled = true;

        if (promo_package) {
            messageApi.info('Вы уже активировали промокод', 2);
            e.target.disabled = false;
            return;
        }

        if (promocode.length === 0) {
            messageApi.error('Пожалуйста введите промокод', 2);
            e.target.disabled = false;
            return;
        }

        if (alias) {
            const formData: PromoCodeFormData = {
                promocode: promocode,
                service: alias,
            }
            dispatch(requestActivatePromoCode(formData));
        }
        e.target.disabled = false;
    }

    useEffect(() => {
        if (status !== Status.CALM && message.length > 0) {
            if (status === Status.SUCCESS) {
                messageApi.success(message, 2);
                return;
            }
            if (status === Status.ERROR) {
                messageApi.error(message, 2);
                return;
            }
        }
    }, [status, message, messageApi])

    // Payment Handler
    const { payment_url } = useSelector(selectPayments);

    const payment_handler = () => {
        if (isDisabled) {
            messageApi.info('Пожалуйста примите пользовательское соглашение', 2);
            return;
        }
        if (activePackage && !isDisabled) {
            const user: WebAppUser = WebApp.initDataUnsafe.user;
            
            const formData: CreateUrlPaymentFormData = {
                alias: activePackage.alias,
                price: promo_package ? promo_package.price : activePackage.price,
                telegram_id: user.id,
                description: activePackage.description,
                verbose_name: activePackage.verbose_name
            }
            dispatch(requestCreatePaymentUrl(formData));
        }
    }

    useEffect(() => {
        if (payment_url) {
            WebApp.openInvoice(payment_url);

            WebApp.onEvent('invoiceClosed', (object: any) => {
                if (object.status === 'paid') {
                    messageApi.success('Вы успешно оплатили услугу', 2);
                    return;
                }
                if (object.status === 'failed') {
                    messageApi.error('Произошла ошибка при оплате', 2);
                    return;
                }
                if (object.status === 'cancelled') {
                    messageApi.info('Вы отменили оплату', 2);
                    return;
                }
            });
        }
        return () => {
            dispatch(setPromocodeIsNull());
            dispatch(setPaymentUrlIsNull());
            dispatch(requestMyServices());
        }
    }, [payment_url, WebApp, dispatch])

    // Button
    const [isDisabled, setIsDisabled] = useState(true);

    const onChange: CheckboxProps['onChange'] = (e) => {
        setIsDisabled(!e.target.checked);
    };

    const handleOpenLink = (link: string) => {
        WebApp.openLink(link);
    }

    const handleBack = () => {
        navigate(-1);
    }

    return (
        <div className={style.service_pay}>
            {contextHolder}
            <BackButton onClick={handleBack} />
            {alias && verbose_name && price
                ? (
                    <>
                        <h1 className={style.service_pay__title}>Оплата</h1>
                        <div className={style.service_pay__service_info}>
                            <h2>Оплата услуги: {verbose_name}</h2>
                        </div>
                        <div className={style.service_pay__promocode}>
                            <form>
                                <input
                                    type="text"
                                    value={promocode}
                                    onChange={(e) => setPromocode(e.target.value)} />
                                <button onClick={handlerActivatePromocode}>Применить</button>
                            </form>
                        </div>
                        <div className={style.service_pay__personal_info}>
                            <Checkbox onChange={onChange} />
                            <p>Даю согласие на обработку персональных данных, соглашаюсь с <span onClick={() => handleOpenLink('https://my.astars.online/media/content/company/%D0%9F%D0%BE%D0%BB%D0%B8%D1%82%D0%B8%D0%BA%D0%B0-%D0%BA%D0%BE%D0%BD%D1%84%D0%B8%D0%B4%D0%B5%D0%BD%D1%86%D0%B8%D0%B0%D0%BB%D1%8C%D0%BD%D0%BE%D1%81%D1%82%D0%B8-ASTARS_RU1.pdf')}>Политикой конфиденциальности</span> и принимаю условия <span onClick={() => handleOpenLink('https://my.astars.online/media/content/company/%D0%9E%D0%A4%D0%95%D0%A0%D0%A2%D0%90_%D0%9F%D0%A0%D0%90%D0%92%D0%98%D0%9B%D0%90_%D0%9F%D0%A0%D0%95%D0%94%D0%9E%D0%A1%D0%A2%D0%90%D0%92%D0%9B%D0%95%D0%9D%D0%98%D0%AF_%D0%A3%D0%A1%D0%9B%D0%A3%D0%93_ASTARS_05_05_6_ajHq8t5.pdf')}>Пользовательского соглашения (Оферты)</span></p>
                        </div>
                        <MainButton
                            color='#9696df'
                            disabled={isDisabled}
                            onClick={payment_handler}
                            text={`Купить ${promo_package ? promo_package.price : activePackage?.price} ₽`}
                        />
                    </>
                )
                : <Spinner />}
        </div>
    )
}

export default ServicePay;