import style from './Blog.module.scss';

// React
import { useEffect } from 'react';

// Router
import { useNavigate, useParams } from 'react-router-dom';

// Redux
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../../redux/store';
import { selectBlog } from '../../redux/blog/selectors';

// Types
import { Status } from '../../types/types';
import { requestGetBlog } from '../../redux/blog/asyncActions';

// Telegram
import { BackButton } from '@vkruglikov/react-telegram-web-app';

// Components
import Spinner from '../../components/utils/spinner/Spinner';
import { BACKEND_URL } from '../../http';
import { setBlogIsNull } from '../../redux/blog/slice';


const BlogComponent = () => {
    const { blogId } = useParams();

    // Navigate
    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/home/');
    }
    
    // Get Blog
    const { blog, status } = useSelector(selectBlog);

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!blog && blogId && status === Status.CALM) {
            dispatch(requestGetBlog(blogId));
        }
    }, [dispatch, blog, blogId, status])

    useEffect(() => {
        return () => {
            dispatch(setBlogIsNull());
        }
    }, [])

    return (
        <div className={style.blog}>
            <BackButton onClick={handleBack} />
            {blog
                ? (
                    <>
                        <img
                            className={style.blog__img}
                            src={BACKEND_URL + blog.image}
                            alt="blog-logo"
                        />
                        <div
                            className={style.blog__content}
                            dangerouslySetInnerHTML={{__html: blog.content.replace('<p>&nbsp;</p>', '')}}></div>

                    </>
                )
                : <Spinner />}
        </div>
    )
}

export default BlogComponent;